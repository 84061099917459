import './App.scss';
// import { BrowserRouter as Router, Routes, useRoutes, useLocation } from "react-router-dom";
import {
  useRoutes,
  useLocation
} from "react-router-dom";
import Header from './header/Headers';
import Footer from './footer/Footer';
import HomePageBlank from './home-page/HomePageBlank';
import AboutPage from './about/AboutPage';
import ScrollToTop from './utility/ScrollToTop';

function App() {
  const location = useLocation()
  let routes = useRoutes([
    { path: "/", element: <HomePageBlank /> },
    { path: "about", element: <AboutPage /> },
  ]);
  return (
    <div className={`page ast-desktop ast-page-builder-template ast-single-post ast-hfb-header elementor-default elementor-kit-398 elementor-page ${location.pathname == '/New' ? "ast-theme-transparent-header" : ''}`}>
      <div className="hfeed site" id="page">
        <ScrollToTop/>
        <Header />
          <div style={{ minHeight: "calc(100vh - 366px - 100px)" }}>
            {routes}
          </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
