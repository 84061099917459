import { NavLink } from "react-router-dom";
import Banners from "../banner/Banners";
import './HomePage.scss'

function HomePageBlank() {
    return (
        <div id="content" className="underConstuction" style={{
            position: "fixed",
            inset: 0,
            background: "#333",
            zIndex: "9999",
            display: "flex",
            flexDirection: "column",
            justifyContent:"space-evenly",
            alignItems: "center",}}>
            <img width="221" height="61" src="images/artsmaniac_logo.svg" className="custom-logo astra-logo-svg" alt="Artist" decoding="async" />
            <h2>this site is under construction</h2>
      </div>
    )
}

export default HomePageBlank
